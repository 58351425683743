<template>
    <section class="header" v-lazy:background-image="this.bg">
        <div v-if="amimation" class="animation slideUp"><img src="/img/bg/formula1.png" alt=""></div>

        <div class="wrapper_header">

            <div class="intro_info">

                <div v-if="subtitle" class="intro_info_subtitle">
                    <p>{{subtitle}}</p>
                </div>

                <div v-if="title" class="intro_info_title">
                    <h1>{{title}}</h1>
                </div>

                <div v-if="description" class="intro_info_description">
                    <p>{{description}}</p>
                </div>

                <div class="btnSeeProduct">

                </div>
                <a v-if="this.btn" class="see" :href="btnLink">
                    {{btnTitle}}
                </a>

                <button @click="downClick" v-if="down" :class="this.white ? 'whiteArrow' : 'btnDown'">
                    <i class="arrow down"></i>
                </button>
            </div>

        </div>
    </section>
</template>

<script>
    export default {
        props: ['title', 'description', 'btn', 'down', 'bg', 'subtitle', 'amimation', 'btnTitle', 'btnLink', 'white'],
        methods: {
            downClick() {
                let windowCoords = document.documentElement.clientHeight;
                if (window.pageYOffset < windowCoords) {
                    window.scrollBy(0, 700);
                } else if (window.pageYOffset > windowCoords) {
                    window.scrollTo(0, windowCoords);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/index.scss';

    .header {
        max-width: 100%;
        margin: 0 auto;
        width: 100%;
        padding-top: 230px;
        background-position: top 80% center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 600px !important;

        @media (max-width: 1200px) {
            padding-top: 160px;
            height: 500px !important;
        }

        @media (max-width: 993px) {
            padding-top: 160px;
            height: 400px !important;
        }

        @media (max-width: 576px) {
            padding-top: 170px;
            height: 300px !important;
        }

        & .animation {
            outline: none;
            position: absolute;
            right: 10%;

            @media (max-width: 575px) {
              right: 5%;
            }

            & img {
                outline: none;
                width: 700px;
                height: 500px;

                @media (max-width: 768px) {
                    width: 500px;
                    max-height: 300px;
                }

                @media (max-width: 575px) {
                    max-width: 350px;
                    max-height: 300px;
                    object-fit: contain;
                }

                @media (max-width: 375px) {
                    max-width: 300px;
                    max-height: 300px;
                    object-fit: contain;
                }
            }
        }

        .slideUp {

            animation: slideUp 20s infinite linear;

            visibility: visible !important;
        }

        @keyframes slideUp {
            0% {
                transform: translateY(115%);
            }

            100% {
                transform: translateY(-130%);
            }
        }

        & .wrapper_header {
            padding: 0 20px;
            margin: 0 auto;
        }

        & .intro_info {
            max-width: 1500px;
            width: 100%;
            margin: 0 auto;
            height: auto;

            & .intro_info_title {
                & h1 {
                    @include title;
                    color: white !important;
                }
            }

            & .intro_info_description {
                & p {
                    @include description;
                    color: white !important;
                }
            }

            & .intro_info_subtitle {
                & p {
                    text-align: left !important;
                    @include subtitle ($colorLightTitle);
                }
            }

            & .see {
                display: inline-flex !important;
                width: auto !important;
                height: 58px !important;
                margin-top: 20px;
                margin-top: 20px;
                padding: 0 15px !important;
                line-height: 58px !important;
                text-align: center !important;
                @include btn;

                @media (max-width: 575px) {
                    line-height: 40px !important;
                    height: 40px !important;
                    font-size: 13px !important;
                    @include btn
                }
            }

            & .btnDown {
                display: block;
                border: none;
                cursor: pointer;
                height: 57px;
                width: 57px;
                border-radius: 50%;
                position: absolute;
                right: 20%;
                bottom: 35%;
                transition: $transitionLink;
                background: rgba(255, 255, 255, 1);
                transform: rotate(-90deg);

                @media (max-width: 992px) {
                    bottom: 23%;
                }

                &:hover {
                    background: rgba(255, 255, 255, 1);
                    transition: $transitionLink;
                }

                i {
                    width: 12px;
                    height: 12px;
                    display: block;
                }

                .down {
                    @include arrowRightLeft(135deg, $base-color)
                }
            }

            & .whiteArrow {
                display: block;
                border: none;
                cursor: pointer;
                height: 60px;
                width: 60px;
                border-radius: 50%;
                position: absolute;
                right: 20%;
                bottom: 35%;
                transition: $transitionLink;
                background: transparent;
                transform: rotate(-90deg);

                @media (max-width: 992px) {
                    bottom: 9%;
                    right: 10%;
                }

                i {
                    width: 17px;
                    height: 17px;
                    border: 7px solid black;
                    font-weight: bold;
                    padding: 2px;
                    display: block;
                    border-width: 0 7px 7px 0 !important;
                }

                .down {
                    @include arrowRightLeft(135deg, #ffffff)
                }
            }

        }


    }
</style>
