<template>

    <div class="item_intro">
        <div class="img">
            <img :src="this.img" :alt="title">
        </div>

        <div class="title">
            <p v-html="title"></p>
        </div>

        <div class="description">
            <p v-html="description"></p>
        </div>

        <a v-if= btnLink :href="btnLink">
            {{btnTitle}}
            <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14.2931 1.70711C13.9026 1.31658 13.9026 0.683417 14.2931 0.292893C14.6837 -0.0976311 15.3168 -0.0976311 15.7074 0.292893L21.7073 6.29289C22.0979 6.68342 22.0979 7.31658 21.7073 7.70711L15.7074 13.7071C15.3168 14.0976 14.6837 14.0976 14.2931 13.7071C13.9026 13.3166 13.9026 12.6834 14.2931 12.2929L18.586 7.99998H1.01103C0.452653 7.99998 0 7.55227 0 6.99998C0 6.4477 0.452653 5.99998 1.01103 5.99998H18.586L14.2931 1.70711Z"
                    fill="#20C8CE" />
            </svg>
        </a>
    </div>

</template>

<script>
    export default {
        name: "productItem",
        locale: window.localStorage.getItem('locale'),
        props: ['title', 'description', 'btnLink', 'btnTitle', 'img'],
    }
</script>

<style scoped lang="scss">
    @import "../../assets/scss/index";

    .item_intro {
        @include cardItem;
        flex-grow: 1;

        & .img {
            align-items: center;
            display: flex;
            width: 97px;
            height: 97px;
            border-radius: 50%;
            background: $colorLinkActiveLight;

            @media (max-width: 769px) {
                width: 77px;
                height: 77px;
            }

            & img {
                margin: auto;
                object-fit: contain;
                max-width: 56px;
                max-height: 56px;
            }
        }

        & .title {
            & p {
                @include titleItem
            }
        }

        & .description {
            & p {
                @include descriptionItem
            }
        }

        & a {
            @include btnNoBcg;
            display: flex;
            align-items: center;
            font-size: 16px !important;

            & svg {
                margin-left: 10px;
            }
        }
    }
</style>